@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'EuclidCircularA';
  src: url('./assets/fonts/swisstypefaces/Euclid Circular A pack/desktop files/EuclidCircularA-Light.ttf')
    format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'EuclidCircularA';
  src: url('./assets/fonts/swisstypefaces/Euclid Circular A pack/desktop files/EuclidCircularA-Regular.ttf')
    format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'TiemposHeadline';
  src: url('./assets/fonts/Klim-Order/WOFF2/tiempos-headline-regular.woff2')
    format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'UbuntuMono';
  src: url('./assets/fonts/Ubuntu_Mono/UbuntuMono-Regular.ttf')
    format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'UbuntuMono';
  src: url('./assets/fonts/Ubuntu_Mono/UbuntuMono-Bold.ttf') format('truetype');
  font-weight: 700;
}

@layer base {
  body {
    font-family: 'EuclidCircularA', sans-serif;
    font-weight: 400;
    @apply text-centigrade-carbon;
    @apply bg-white;
  }

  footer {
    font-family: 'EuclidCircularA', sans-serif;
    font-weight: 400;
    @apply text-centigrade-creme;
    @apply bg-centigrade-carbon;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'TiemposHeadline', sans-serif;
    font-weight: 700;
  }

  h1 {
    @apply mt-3;
    @apply text-4xl;
  }

  h2 {
    @apply mt-2;
    @apply text-3xl;
  }

  h3 {
    @apply mt-2;
    @apply text-2xl;
  }

  h4 {
    @apply mt-1;
    @apply text-xl;
  }

  code {
    @apply bg-centigrade-carbon-400;
    @apply text-centigrade-sand;
    @apply font-code;
    @apply p-4;
    @apply rounded-lg;
    @apply text-xs;
  }
}

@layer components {
  .sticky-footer {
    @apply bg-centigrade-carbon;
    /* @apply fixed; */
    @apply inset-x-0;
    @apply bottom-0;
    @apply p-4;
  }

  .tooltip {
    position: relative;
    /* Add other styles as needed for your link */
  }

  .tooltip .tooltip-text {
    @apply absolute bg-gray-600 text-white text-sm px-2 py-1 rounded-md -mt-8 ml-2 opacity-0 pointer-events-none transition-opacity duration-300 ease-in-out;
    /* Adjust positioning and other styles as needed for your tooltip */
  }

  .tooltip:hover .tooltip-text {
    @apply opacity-100;
  }

  /* Style the list */
  ol.breadcrumb {
    @apply p-1 list-none rounded-lg bg-centigrade-creme-200 mb-1 text-centigrade-carbon;
    /* padding: 10px 16px;
      list-style: none;
      background-color: #eee; */
  }

  /* Display list items side by side */
  ol.breadcrumb li {
    /* display: inline;
      font-size: 18px; */
    @apply inline text-sm;
  }

  /* Add a slash symbol (/) before/behind each list item */
  ol.breadcrumb li + li:before {
    @apply text-gray-400 p-2;
    content: '/\00a0';
  }

  /* Add a color to all links inside the list */
  ol.breadcrumb li a {
    @apply text-centigrade-carbon-100 underline cursor-pointer;
  }

  /* Add a color on mouse-over */
  ol.breadcrumb li a:hover {
    @apply text-centigrade-sky underline cursor-pointer;
    /* color: #01447e;
      text-decoration: u */
  }

  .scrollable-overflow {
    overflow: auto; /* Enable scroll bar when content overflows */
  }

  .glow-yellow {
    text-shadow: rgb(34, 30, 13) 3px 0 20px;
  }

  .div-pill {
    @apply uppercase rounded-full pl-4 pr-4 text-white font-bold text-center items-center justify-center flex flex-row;
  }

  .link-common {
    @apply text-centigrade-carbon-100 hover:text-centigrade-sky underline hover:cursor-pointer;
  }
  .div-link {
    @apply cursor-pointer hover:underline;
  }
  .btn-common-naked {
    @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer;
  }
  .btn-common {
    @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer bg-slate-600 hover:bg-cyan-600 text-white hover:text-yellow-300 font-bold;
  }
  .btn-approve {
    @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer bg-green-700 hover:text-white text-white hover:bg-green-500;
  }
  .btn-cancel {
    @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer bg-slate-300 hover:bg-slate-700 hover:text-white text-black;
  }
  .link-align-center {
    @apply text-center justify-center items-center;
  }
  .alert-success {
    @apply bg-green-200 text-green-800 border-2 border-green-600 rounded-lg p-2;
  }
  .alert-danger {
    @apply bg-red-200 text-red-800 border-2 border-red-600 rounded-lg p-2;
  }
  .input-common {
    @apply bg-gray-200 text-slate-700 p-2 rounded-lg;
  }
}

.dropdown {
  position: relative;
}

.dropdown:before {
  content: '';
  position: absolute;
  right: 10px;
  top: 8px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
}

.dropdown:after {
  content: '';
  position: absolute;
  right: 10px;
  top: 3px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #333;
}

@layer utilities {
  .break-anywhere {
    overflow-wrap: anywhere;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(var(--scale-start, 0));
  }
  to {
    opacity: 1;
    transform: scale(var(--scale-end, 1));
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + 25px));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
